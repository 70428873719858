@import "./tailwind.css";
@import "./tailwind-preflight.css";

@font-face{
	font-family: digital;
	src:url('../font/digital.ttf'); /* IE9+,可以是具体的实际链接 */
}
@font-face{
	font-family: clock;
	src:url('../font/clock.ttf'); /* IE9+,可以是具体的实际链接 */
}

/**
* ---------------------------------------------------------------------------------------------------
* general
* ---------------------------------------------------------------------------------------------------
*/
body,
html {
  @apply w-full h-full overflow-x-hidden;
}

/**
* ---------------------------------------------------------------------------------------------------
* blueprint specific css overrides
* ---------------------------------------------------------------------------------------------------
*/
.bp3-panel-stack-view {
  background: none;
}

/**
* ---------------------------------------------------------------------------------------------------
* custom css
* ---------------------------------------------------------------------------------------------------
*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--ads-v2-color-bg-emphasis) transparent;
}

/* For some reason, margin-left: -160px; is being added to the react-toastify stylesheet on the .Toastify__toast-container--top-center
and .Toastify__toast-container--bottom-center classes, which messes with the placement of toast. This line resets that. */
.Toastify__toast-container--top-center,
.Toastify__toast-container--bottom-center {
  margin-left: 0;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--ads-v2-color-bg-emphasis);
  border-radius: 36px;
  opacity: 0.5;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

input::placeholder,
textarea::placeholder {
  color: var(--ads-v2-color-fg-subtle);
}
